import { createStore, createLogger } from 'vuex'
// import { createStore } from 'vuex'
import { actions } from './actions'
import { goals } from './goals'
import auth from './store.auth'
// import VuexPersistence from 'vuex-persist'

/*
const vuexLocal = new VuexPersistence({
  key: 'kompass-vuex',
  storage: window.localStorage,
  reducer: (state) => ({
    users: state.users,
    userSettings: state.userSettings,
    cmsLocale: state.cmsLocale,
    auth: state.auth,
  }),
})
*/
const disableLogger = true

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /\.js$/,
)
const servicePlugins = requireModule
  .keys()
  .map(modulePath => requireModule(modulePath).default)

const store = createStore({
  state: {
    userSettings: {
      tooltips: false,
      activeSubCategories: [],
      disableSave: true,
      workshopSteps: [],
    },
    cmsLocale: 'no_NO',
    frameworkId: 1,
    projectTitle: '',
  },
  mutations: {
    setWorkshopSteps (state, steps) {
      state.userSettings.workshopSteps = steps
    },
    setCmsLocale (state, value) {
      state.cmsLocale = value
      // Set in local storage
      localStorage.setItem('user-lang', value)
    },
    toggleDisableSave (state, value) {
      state.userSettings.disableSave = value
    },
    toggleTooltips (state) {
      state.userSettings.tooltips = !state.userSettings.tooltips
    },
    toggleSubCategoryActive (state, id) {
      const subCats = state.userSettings.activeSubCategories
      const i = subCats.findIndex(subId => subId === id)
      if (i !== -1) {
        subCats.splice(i, 1)
      } else {
        subCats.push(id)
      }
    },
    setProjectTitle (state, title) {
      state.projectTitle = title
    },
  },
  actions: {
    async setCmsLocale ({ commit, dispatch }, language) {
      commit('setCmsLocale', language)
      dispatch('refreshCatsAndSubCats')
      dispatch('refreshStandardActions')
    },
    async refreshCatsAndSubCats ({ commit, dispatch, state }) {
      const params = {
        query: {
          $limit: 99999,
          locale: state.cmsLocale,
        },
      }
      const calls = [
        dispatch('frameworks/find'),
        dispatch('categories/find', params),
        dispatch('frameworks-categories/find', params),
        dispatch('sub-categories/find', {
          query: {
            ...params.query,
            localeRequired: false,
          },
        }),
        dispatch('sub-categories-defaults/find', {}),
      ]
      await Promise.all(calls)
    },
    async refreshStandardActions ({ dispatch, commit, state }, payload) {
      if (!store.getters['auth/isAuthenticated']) {
        return
      }
      try {
        commit('standard-actions/clearAll')
        commit('tags/clearAll')
        const query = {
          $limit: 10000,
          locale: state.cmsLocale,
          localeRequired: false,
        }
        if (payload?.countryId) {
          query.countryId = payload.countryId
        }
        if (payload?.isEnabled !== undefined) {
          query.isEnabled = payload.isEnabled
        }
        await dispatch('standard-actions/find', { query })
        await dispatch('standard-action-ratings/find')
        await dispatch('tags/find', { query: { locale: state.cmsLocale } })
      } catch (error) {
        console.log(error)
        throw new Error(error)
      }
    },
    async loadCompassData ({ dispatch, commit, state }, ids) {
      try {
        console.log('loadCompassData', ids)
        await dispatch('compasses/find')
        commit('compass-sub-categories/clearAll')
        const compassId = ids.length > 1 ? { $in: state.compasses.ids } : Number(ids[0])
        const calls = [
          dispatch('compass-sub-categories/find', { query: { compassId, locale: state.cmsLocale } }),
          dispatch('compass-actions/find', { query: { compassId } }),
          dispatch('compass-categories-ratings/find', { query: { compassId } }),
          dispatch('compass-user-ratings/find', { query: { compassId } }),
          dispatch('compass-goals/find', { query: { compassId } }),
          dispatch('board-notes/find', { query: { compassId } }),
          dispatch('board-notes-content/find', { query: { compassId } }),
        ]
        await Promise.all(calls)
        await dispatch('action-ratings/find', {
          query: {
            compassActionId: {
              $in: state['compass-actions'].ids,
            },
            $limit: 9999,
          },
        })
        console.log('loadCompassData done')
      } catch (error) {
        throw new Error(error)
      }
    },
  },
  modules: {
    actions,
    goals,
  },
  plugins: [
    ...servicePlugins,
    auth,
    // vuexLocal.plugin,
    process.env.NODE_ENV !== 'production' && !disableLogger ? createLogger() : () => {},
  ],
})

export {
  store,
}
