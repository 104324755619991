import { store } from '@/store'

export default async (allowDisabled = true, countryId = null) => {
  /**
   * IN ACTION DESIGNER:
   * All users can find collections that have created themselves (userId === user.id)
   * All users can find collections their organization has access to via actionCollectionsOrganizations
   * All users can find collections
   * - that was created by a user in their organisation (user.organizationId === collection.organizationId)
   * - and have visibility set to 'organisation' (collection.visibility === 'organisation')
   * - and is enabled (collection.isEnabled === true)
   * Org and Super Admins can additionally find collections that
   * - was created by a user in their organisation (user.organizationId === collection.organizationId)
   * - and have visibility set to 'organisation' (collection.visibility === 'organisation')
   * - and is a draft (collection.isEnabled === false)
   *
   * IN LIBRARY:
   * Same as above but only where enabled is true, and where a actionCollectionOrganizationCountry that matches the projects country
   */
  try {
    const user = store.getters['auth/user']
    const userId = user.id
    const isSuperAdmin = user.roleId === 3
    const isOrgAdmin = user.roleId === 4
    const userOrgId = user.organizationId

    const allowedCases = [{ userId: userId }]

    if (isSuperAdmin || isOrgAdmin) {
      allowedCases.push({ organizationId: userOrgId, visibility: { $or: ['organization', 'organizations'] } })
    } else {
      allowedCases.push({ organizationId: userOrgId, visibility: 'organization', isEnabled: true })
      allowedCases.push({ visibility: 'organizations' /* AND IS VISIBLE TO MY ORG */ })
    }
    const query = {
      $or: allowedCases,
    }
    if (!allowDisabled) {
      query.isEnabled = true
    }
    if (countryId) {
      query.countryId = countryId
    }
    store.commit('action-collections/clearAll')
    const collections = await store.dispatch('action-collections/find', { query })
    return collections.data
  } catch (error) {
    console.log('Error:', error)
  }
}
