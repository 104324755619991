import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

/* const parse = (context) => {
  context.result.data.forEach(item => {
    item.description = item.defaultTranslation.description
    item.title = item.defaultTranslation.title
  })
} */

class Category extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.

  static modelName = 'Category'
  // Define default properties here
  static instanceDefaults () {
    return {
      icon: '',
      color: '',
      frameworkId: null,
    }
  }
}
const servicePath = 'categories'
const servicePlugin = makeServicePlugin({
  Model: Category,
  service: feathersClient.service(servicePath),
  servicePath,
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default servicePlugin
