import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

// Define the Organization model
class Organization extends BaseModel {
  static modelName = 'Organization'
  static instanceDefaults () {
    return {
      name: '',
    }
  }
}
// Create the service plugin
const servicePath = 'organizations'
const servicePlugin = makeServicePlugin({
  Model: Organization,
  service: feathersClient.service(servicePath),
  servicePath,
})
export default servicePlugin
