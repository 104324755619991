<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 49.17 47.87"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_3" data-name="Layer 3">
        <path fill="none" :stroke="color" stroke-miterlimit="10" stroke-width="1.5px" d="M7.18,33.28A21.32,21.32,0,0,1,25,4.64"/>
        <polygon :fill="color" points="11.72 26.67 10.37 26.02 7.16 32.67 0.75 28.98 0 30.28 7.8 34.78 11.72 26.67"/>
        <path fill="none" :stroke="color" stroke-miterlimit="10" stroke-width="1.5px" d="M43.56,39.38a21.31,21.31,0,0,1-33.85-1.3"/>
        <polygon :fill="color" points="35.56 38.76 35.68 40.26 43.05 39.7 43.07 47.1 44.57 47.09 44.55 38.08 35.56 38.76"/>
        <path fill="none" :stroke="color" stroke-miterlimit="10" stroke-width="1.5px" d="M30.47,4.79A21.31,21.31,0,0,1,46.41,34.87"/>
        <polygon :fill="color" points="34.01 11.99 35.24 11.13 31.01 5.07 37.37 1.29 36.6 0 28.86 4.61 34.01 11.99"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1d1d1b',
    },
  },
}
</script>

<style scoped>

</style>
