import { store } from '@/store'

const isMappedToFrameworkCategory = (subCategoryId, frameworkCategoryId) => {
  const mapIndex = store.getters['sub-categories-defaults/list'].findIndex(mapping => (
    mapping.subCategoryId === subCategoryId &&
    mapping.frameworksCategoryId === frameworkCategoryId
  ))
  return mapIndex !== -1
}
export const getCategoryEffectRating = (compassId, frameworkId, categoryId) => {
  // TODO implement for explore mode
  // get the compassAction.subCategory

  if (frameworkId === 1) {
    const ratings = store.getters['action-ratings/find']({
      query: { compassId, categoryId },
    }).data
    return ratings.reduce((sum, rating) => sum + rating.rating, 0)
  } else if (frameworkId === 3) {
    // the categoryId is the id of the Impact architecture category
    // eg. [301, 302, ...]

    // 1. find all Link Compass subCategories that is mapped to this
    // eg. [45, 46, 88, 89, 11, 10, ...]
    const mappedSubCatIds = store.getters['sub-categories-defaults/find']({
      query: {
        frameworksCategoryId: categoryId,
      },
    }).data.map(mapping => mapping.subCategoryId) // the subCategoryId here is the Link Compass subCategoryId

    // 2. find all compass actions that is in one of these subCategories
    const compassActionIds = store.getters['compass-actions/find']({
      query: {
        compassId,
        subCategoryId: {
          $in: mappedSubCatIds,
        },
      },
    }).data.map(compassAction => compassAction.id)
    // 3. find all corresponding ratings
    const ratings = store.getters['action-ratings/find']({
      query: {
        compassActionId: {
          $in: compassActionIds,
        },
      },
    }).data
    // 4. return the sum of all the ratings = totalRating for Impact Arch Category
    return ratings.reduce((sum, rating) => sum + rating.rating, 0)
    // TODO:
    // What happens when a compassAction has multiple ratings? (Hint: it makes no sense)
  }
}

// @todo REFACTOR THIS
export const getCategoryEffectRatingOld = (compassId, frameworkId, categoryId) => {
  let categoryRatingSum = 0
  const query = compassId ? { compassId } : {}
  const ratings = store.getters['action-ratings/find']({ query }).data
  const compassActions = store.getters['compass-actions/find']({ query }).data
  // explore version - should not be saved
  if (!compassId) {
    ratings.forEach(rating => {
      const actionSubCategoryId = compassActions.find(a => a.id === rating.compassActionId)?.subCategoryId
      if (frameworkId !== 1 && isMappedToFrameworkCategory(actionSubCategoryId, categoryId)) {
        categoryRatingSum += rating.rating
      } else if (rating.categoryId === categoryId) {
        categoryRatingSum += rating.rating
      }
    })
    return categoryRatingSum
  } else {
    const activeSubCategories = store.getters['compass-sub-categories/list'].filter(subCat => subCat.compassId === compassId)
    ratings.forEach(rating => {
      // Fix double summed categoryRatingSum
      // Still needs to be refactored
      let isSummed = false
      const actionSubCategoryId = compassActions.find(a => a.id === rating.compassActionId)?.subCategoryId
      const actionIsInActiveSubCats = activeSubCategories.findIndex(subCat => subCat.subCategoryId === actionSubCategoryId) !== -1
      if (actionSubCategoryId && actionIsInActiveSubCats) {
        if (frameworkId !== 1 && isMappedToFrameworkCategory(actionSubCategoryId, categoryId)) {
          categoryRatingSum += rating.rating
          isSummed = true
        } else if (rating.categoryId === categoryId) {
          categoryRatingSum += rating.rating
          isSummed = true
        }
      }
      if (!isSummed) {
        const actionCompassSubCategoryId = compassActions.find(a => a.id === rating.compassActionId)?.compassSubCategoryId
        const actionIsInCompassSubCats = activeSubCategories.findIndex(subCat => subCat.id === actionCompassSubCategoryId) !== -1
        if (actionCompassSubCategoryId && actionIsInCompassSubCats && rating.categoryId === categoryId) {
          categoryRatingSum += rating.rating
        }
      }
    })
    return categoryRatingSum
  }
}
