import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Projects from '../views/Projects.vue'
import { store } from '../store/index'
import UserLogout from '../views/UserLogout.vue'

const routes = [
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "test" */ '../views/Test.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/mine-projekter',
    name: 'MyProjects',
    component: Projects,
    meta: { requiresAuth: true },
  },
  {
    path: '/projekt/:id',
    name: 'Project',
    props: true,
    component: () => import(/* webpackChunkName: "project" */ '../views/Project.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/report-designer/:compassId/:reportId?',
    name: 'ReportDesigner',
    props: true,
    component: () => import(/* webpackChunkName: "report-designer" */ '../views/ReportDesigner.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/report/:reportId/:uuid',
    name: 'ReportView',
    props: true,
    component: () => import(/* webpackChunkName: "report" */ '../views/Report.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/workshop/:compassId/:workshopStep?/:guideStep?',
    name: 'Workshop',
    props: true,
    component: () => import(/* webpackChunkName: "workshop" */ '../views/Workshop.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/kompass/:compassId',
    name: 'FullscreenCompass',
    props: true,
    component: () => import(/* webpackChunkName: "project" */ '../views/FullscreenCompass.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/try',
    name: 'ExploreMobileIntro',
    component: () => import(/* webpackChunkName: "try" */ '../views/ExploreMobileIntro.vue'),
    meta: { disableSave: true },
  },
  {
    path: '/utforsk/:compassId?',
    name: 'ExploreMobile',
    props: true,
    component: () => import(/* webpackChunkName: "try" */ '../views/ExploreMobile.vue'),
    meta: { disableSave: true },
  },
  {
    path: '/forslag/:pin',
    name: 'GuestAddActions',
    props: true,
    component: () => import(/* webpackChunkName: "guest" */ '../views/GuestAddActions.vue'),
  },
  {
    path: '/kompass',
    name: 'AboutCompass',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "aboutCompass" */ '../views/AboutCompass.vue'),
  },
  {
    path: '/om',
    name: 'AboutLink',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "aboutLink" */ '../views/AboutLink.vue'),
  },
  {
    path: '/users/created',
    name: 'CreatedUser',
    component: () => import(/* webpackChunkName: "usercreate" */ '../views/CreatedUser.vue'),
  },
  {
    path: '/login',
    name: 'UserLogin',
    component: () => import(/* webpackChunkName: "login" */ '../views/UserLogin.vue'),
  },
  {
    path: '/logout',
    name: 'UserLogout',
    component: UserLogout,
  },
  {
    path: '/change-password',
    name: 'UserPassword',
    component: () => import(/* webpackChunkName: "login" */ '../views/UserPassword.vue'),
  },
  {
    path: '/users/validate/:hash/:email',
    name: 'UserValidate',
    component: () => import(/* webpackChunkName: "uservalidate" */ '../views/UserValidate.vue'),
  },
  {
    path: '/cms',
    name: 'cms',
    component: () => import(/* webpackChunkName: "cms" */ '../views/Cms.vue'),
    meta: { requiresAuth: true, requiresSuperAdmin: true },
    children: [
      {
        path: '',
        name: 'CmsMain',
        component: () => import(/* webpackChunkName: "cmsmain" */ '@/components/cms/CmsMain.vue'),
      },
      {
        path: 'category/:categoryId',
        name: 'CmsCategory',
        props: (route) => ({ categoryId: Number(route.params.categoryId) }),
        component: () => import(/* webpackChunkName: "cmscategory" */ '@/components/cms/CmsCategory.vue'),
      },
    ],
  },
  {
    path: '/ams',
    name: 'ActionManagementSystem',
    component: () => import(/* webpackChunkName: "ams" */ '../components/actionDesigner/ActionDesigner.vue'),
  },
  // Add a new route for the admin view
  {
    path: '/admin/organizations',
    name: 'OrganizationManagement',
    component: () => import(/* webpackChunkName: "OrganizationManagement" */ '../views/AdminOrgManagement.vue'),
    meta: { requiresAuth: true, requiresSuperAdmin: true },
  },
  {
    path: '/admin/users',
    name: 'UserManagement',
    component: () => import(/* webpackChunkName: "UserManagement" */ '../views/AdminUserManagement.vue'),
    meta: { requiresAuth: true, requiresAdmin: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      document.documentElement.scrollTop = 0
    }
  },
  routes,
})

router.beforeEach(async (to, from, next) => {
  to.meta.disableSave ? store.commit('toggleDisableSave', true) : store.commit('toggleDisableSave', false)
  if (localStorage.getItem('kompass-jwt') && !store.getters['auth/isAuthenticated'] && !to.meta.requiresAuth) {
    await store.dispatch('auth/authenticate', {
      strategy: 'jwt',
      accessToken: localStorage.getItem('kompass-jwt'),
    })
    next()
  } else if (to.meta.requiresAuth) {
    if (localStorage.getItem('kompass-jwt')) {
      try {
        const authResponse = await store.dispatch('auth/authenticate', {
          strategy: 'jwt',
          accessToken: localStorage.getItem('kompass-jwt'),
        })
        if (!authResponse.user) {
          await store.dispatch('auth/logout')
        } else if (to.meta.requiresAdmin && ![3, 4].includes(store.getters['auth/user'].roleId)) {
          next(false)
        } else if (to.meta.requiresSuperAdmin && store.getters['auth/user'].roleId !== 3) {
          next(false)
        } else {
          next()
        }
      } catch (e) {
        await store.dispatch('auth/logout')
        next(false)
      }
    } else {
      await store.dispatch('auth/logout')
      next(false)
    }
  } else next()
})

export default router
