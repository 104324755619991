<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 44.02 44.02"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_3" data-name="Layer 3">
        <path fill="none" :stroke="color" stroke-miterlimit="10" stroke-width="1.5px" d="M.75,22A21.27,21.27,0,0,1,30.52,2.52l-1.61,7.9L35.53,5.6a21.26,21.26,0,0,1-1.18,33.72l-5-3.85.54,6.29a21.29,21.29,0,0,1-27-10.41L7.36,25Z"/>
        <circle fill="none" :stroke="color" stroke-miterlimit="10" stroke-width="1.5px" cx="22.01" cy="22.01" r="11.06"/>
        <circle fill="none" :stroke="color" stroke-miterlimit="10" stroke-width="1.5px" cx="22.01" cy="22.01" r="5.12"/>
        <path :fill="color" d="M22,21.81a.2.2,0,1,1-.2.2.2.2,0,0,1,.2-.2m0-1.5a1.7,1.7,0,1,0,1.7,1.7,1.7,1.7,0,0,0-1.7-1.7Z"/>
        <path fill="none" :stroke="color" stroke-miterlimit="10" stroke-width="1.5px" d="M32.07,8.29A17,17,0,0,1,31,36.47"/>
        <path fill="none" :stroke="color" stroke-miterlimit="10" stroke-width="1.5px" d="M5.07,23.52C5,23,5,22.52,5,22A17,17,0,0,1,29.38,6.68"/>
        <path fill="none" :stroke="color" stroke-miterlimit="10" stroke-width="1.5px" d="M29.33,37.36A17,17,0,0,1,5.84,27.29"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1d1d1b',
    },
  },
}
</script>

<style scoped>

</style>
