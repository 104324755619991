<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30.58 44.54"
    fill="none" :stroke="color" stroke-miterlimit="10" stroke-width="1.5px"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_3" data-name="Layer 3">
        <path class="cls-1" d="M29.83,29.25a14.54,14.54,0,0,1-29.08,0c0-8,14.54-28,14.54-28S29.83,21.22,29.83,29.25Z"/>
        <path class="cls-1" d="M24.16,29.25a8.88,8.88,0,0,1-8.87,8.87"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1d1d1b',
    },
  },
}
</script>

<style scoped>

</style>
