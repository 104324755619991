import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class ActionCollectionsOrganizations extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.

  static modelName = 'ActionCollectionsOrganizations'
  // Define default properties here
  static instanceDefaults () {
    return {
      actionCollectionId: null,
      organizationId: null,
    }
  }
}
const servicePath = 'action-collections-organizations'
const servicePlugin = makeServicePlugin({
  Model: ActionCollectionsOrganizations,
  service: feathersClient.service(servicePath),
  servicePath,
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default servicePlugin
