<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 67.74 50.91"
    fill="none"
    :stroke="color"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_3" data-name="Layer 3">
        <path class="cls-1" d="M35.71,24.73a9,9,0,0,0-5.27,1.72,8.8,8.8,0,0,0,.17-1.72,9,9,0,1,0-17.13,3.74,7.15,7.15,0,0,0,1.18,14.21H35.71a9,9,0,1,0,0-17.95Z"/>
        <line class="cls-1" x1="16.99" y1="42.68" x2="21.05" y2="50.56"/>
        <line class="cls-1" x1="22.07" y1="42.68" x2="26.14" y2="50.56"/>
        <line class="cls-1" x1="27.16" y1="42.68" x2="31.22" y2="50.56"/>
        <line class="cls-1" x1="32.25" y1="42.68" x2="36.31" y2="50.56"/>
        <path class="cls-1" d="M52.09,31.53a4.23,4.23,0,1,1,4.23,4.23h-9"/>
        <path class="cls-1" d="M64.23,32.14a2.76,2.76,0,1,1,0,5.51H60.89"/>
        <path class="cls-1" d="M56.46,37.94a2.37,2.37,0,0,1,0,4.74H43.36"/>
        <path class="cls-2" d="M14.25,19.79A4.59,4.59,0,1,1,20.16,16"/>
        <line class="cls-2" x1="3.08" y1="6.05" x2="8.96" y2="10.41"/>
        <line class="cls-2" x1="12.01" y1="0.15" x2="13.75" y2="7.41"/>
        <line class="cls-2" x1="22.65" y1="1.36" x2="19.46" y2="7.76"/>
        <line class="cls-2" x1="30.02" y1="9.12" x2="23.33" y2="12.03"/>
        <line class="cls-2" x1="4.31" y1="26.14" x2="9.63" y2="21.09"/>
        <line class="cls-2" x1="0.04" y1="16.32" x2="7.24" y2="15.88"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1d1d1b',
    },
  },
}
</script>

<style scoped>
.cls-1
.cls-2 {
  stroke-miterlimit:10;
}
.cls-1 {
  stroke-width:1.5px;
}
.cls-2 {
  stroke-width:1.24px;
}
</style>
