<template>
  <li class="cursor-pointer hover:bg-gray-100 rounded p-2" @click="$router.push(`/projekt/${projectId}`)">
    <!-- <div class="bg-gray-200 w-full h-96"></div> -->
    <RoseChart
      :compassId="projectId"
      :chartId="`project_${projectId}`"
      :showLabels="false"
      :clickable="false"
      :canSelectCategory="false"
      :canSaveRatings="false"
      :highlightSelectedCategory="false"
      :compassUserRatingId="compassUserRatingId"
    />
    <div class="relative">
      <h2 class="text-2xl mt-2 text-center">
        {{ projectTitle !== '' ? projectTitle : 'NO NAME' }}
      </h2>
      <div v-if="project.userId !== user.id" class="text-sm text-gray-500 text-center p-1 border-2 text-uppercase w-fit absolute right-0 -top-10">
        {{ $t('Projects.Project list view.SharedWithMe') }}
      </div>
    </div>
  </li>
</template>

<script>
import RoseChart from '@/components/shared/RoseChart'
export default {
  components: {
    RoseChart,
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    projectTitle: {
      type: String,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    compassUserRatingId () {
      const query = {
        compassId: this.projectId,
        frameworkId: 1,
        isHighlighted: true,
      }
      const result = this.$store.getters['compass-user-ratings/find']({ query })
      return result.data[0] ? result.data[0].id : null
    },
  },
}
</script>

<style scoped>
  .w-fit {
    width: fit-content;
  }
</style>
