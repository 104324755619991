<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66.7 58.82"
    fill="none" :stroke="color" stroke-miterlimit="10" stroke-width="0px"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_3" data-name="Layer 3">
        <path :fill="color" d="M40.83,14.22a7.49,7.49,0,1,0-7.48,7.48A7.49,7.49,0,0,0,40.83,14.22Zm-13.47,0a6,6,0,1,1,6,6A6,6,0,0,1,27.36,14.22Z"/>
        <path :fill="color" d="M29.52,41.21,29,39.79c-2.07.7-3.26,1.87-3.26,3.21,0,2.19,3.25,3.85,7.57,3.85s7.57-1.66,7.57-3.85c0-1.31-1.14-2.46-3.14-3.17l-.5,1.42c1.3.46,2.14,1.14,2.14,1.75,0,1-2.31,2.35-6.07,2.35S27.28,44,27.28,43C27.28,42.37,28.16,41.67,29.52,41.21Z"/>
        <path :fill="color" d="M64.43,42.4a3.4,3.4,0,0,1,.77,2c0,4.72-12.8,9.79-31.85,9.79-8.51,0-16.68-1.1-22.69-3l5.84-2.7-.63-1.36L7.69,50.94l4.36,7.88,1.32-.73-3-5.39a80.18,80.18,0,0,0,23,3c18.7,0,33.35-5,33.35-11.29a4.84,4.84,0,0,0-1.07-2.94Z"/>
        <path :fill="color" d="M28.31,34.78c2.32,3.74,4.22,6.51,4.42,6.8l.62.9.62-.9c.2-.29,2.1-3,4.41-6.8a73.68,73.68,0,0,1,17.15,2.73l-5.79,2.81.66,1.35,8.1-3.93L54,29.94l-1.3.75L55.77,36a74.13,74.13,0,0,0-16.5-2.69c3.82-6.32,8.3-14.67,8.3-19.11a14.22,14.22,0,1,0-28.44,0c0,4.44,4.47,12.79,8.29,19.1C13.59,34.15,0,38.15,0,44.44a4.78,4.78,0,0,0,.88,2.68l1.25-.84a3.24,3.24,0,0,1-.63-1.84C1.5,39.75,13.24,35.54,28.31,34.78Zm5-33.28A12.74,12.74,0,0,1,46.07,14.22c0,6.22-10.1,21.68-12.72,25.6-2.63-3.92-12.72-19.38-12.72-25.6A12.74,12.74,0,0,1,33.35,1.5Z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1d1d1b',
    },
  },
}
</script>

<style scoped>
</style>
