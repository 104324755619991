<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 41.55 44.67"
    fill="none" :stroke="color" stroke-width="1.5px"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_3" data-name="Layer 3">
        <circle class="cls-1" cx="10.75" cy="6.28" r="5.53"/>
        <polyline class="cls-1" points="8.32 44.67 8.32 22.29 13.17 22.29 13.17 44.67"/>
        <circle class="cls-1" cx="30.8" cy="6.28" r="5.53"/>
        <polyline class="cls-2" points="40.83 29.48 36.61 15.88 25 15.88 20.77 29.48 16.55 15.88 4.94 15.88 0.72 29.48"/>
        <polyline class="cls-1" points="28.38 44.67 28.38 22.29 33.23 22.29 33.23 44.67"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1d1d1b',
    },
  },
}
</script>

<style scoped>
.cls-1{stroke-miterlimit:10;}.cls-2{stroke-linejoin:bevel;}
</style>
