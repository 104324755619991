<template>
  <div class="relative">
    <label
      :for="id"
      :class="showLabel ? 'text-sm text-gray-700' : 'sr-only'"
    >
      {{ label }}
    </label>
    <input
      :id="id"
      :ref="id"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :type="type"
      :name="label"
      :min="min"
      :max="max"
      class="shadow-sm focus:ring-0 focus:border-black bg-transparent block w-full p-0 border-gray border-0"
      :class="{
        'border-b-2': !readonly,
        'text-sm': size === 'sm',
        'text-md': size === 'md',
        'text-lg': size === 'lg',
        'text-xl': size === 'xl',
        'text-2xl': size === '2xl',
        'pl-4 text-right': type === 'number',
      }"
      :readonly="readonly"
      style="padding-top: 3px; padding-bottom: 3px;"
      :placeholder="label"
    />
    <p v-if="validationMessage" data-cy="validation-error" class="text-red-500 text-xs">{{ validationMessage }}</p>
    <span
      v-if="type === 'number'"
      class="absolute bottom-1 left-0"
    >
      {{ modelValue < 0 ? '' : '+' }}
    </span>
    <BaseIcon
      v-if="iconName"
      :icon="iconName"
      class="absolute top-1 right-0"
      style="top: 0.4rem; height: 1rem; width: 1rem;"
    />
  </div>
</template>

<script>
import BaseIcon from './BaseIcon.vue'

export default {
  components: {
    BaseIcon,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    validationMessage: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    iconName: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    min: {
      type: [String, Number],
      default: 0,
    },
    max: {
      type: [String, Number],
      default: 100,
    },
  },
  emits: ['update:modelValue'],
  mounted () {
    if (this.focus) {
      this.$nextTick(() => this.$refs[this.id]?.focus())
    }
  },
}
</script>
