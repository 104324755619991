<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 46.95 47.07"
    fill="none" :stroke="color"
    stroke-miterlimit="10" stroke-width="0px"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_3" data-name="Layer 3">
        <path :fill="color" d="M36,6.9,6.8,36.06l.44.44L3.55,40.19l6.88,6.88,3.69-3.69.44.44L43.72,14.66l.28,0,3-11-11,3Zm.47,6.23L11.21,38.35,8.92,36.06,36.11,8.87ZM13.06,42.32,10.43,45,5.68,40.19,8.3,37.56l2.38,2.38Zm1.5-.62-2.29-2.29L37.49,14.19l4.26.32ZM44.09,8.54l-2-2,2.75-.74ZM40.41,7l3.24,3.23-.78,2.88-5-.37-.37-5Z"/>
        <path :fill="color" d="M34,27.34,32.93,28.4l9.1,9a3.31,3.31,0,0,1,0,4.69,3.32,3.32,0,0,1-4.67,0l-9.12-9-1.06,1.06,9.13,9a4.81,4.81,0,0,0,6.77-6.83Z"/>
        <path :fill="color" d="M13.29,20.37l3.14,3.11,1.06-1.06L13.7,18.66l-.44.15a8.85,8.85,0,0,1-9.17-2.13A9,9,0,0,1,1.67,8.62l5.59,5.59,5.6-5.64,0,0,1.33-1.33L8.62,1.67a8.89,8.89,0,0,1,10.05,12l-.18.46,3.68,3.65,1.06-1.07-3-3A10.4,10.4,0,0,0,6.93.58L5.81,1l6.25,6.25-4.8,4.86L1,5.81.58,6.93A10.45,10.45,0,0,0,3,17.74,10.33,10.33,0,0,0,13.29,20.37Z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1d1d1b',
    },
  },
}
</script>

<style scoped>
.cls-1{fill:#1d1d1b;}
</style>
