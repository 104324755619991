<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39.76 51.44"
    fill="none"
    :stroke="color"
    stroke-miterlimit="10"
    stroke-width="1.5px"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_3" data-name="Layer 3">
        <polyline class="cls-1" points="2.69 50.69 0.75 50.69 0.75 48.74"/>
        <polyline class="cls-2" points="0.75 46.84 0.75 21.31 9.49 12.09 17.89 3.2"/>
        <polyline class="cls-1" points="18.55 2.51 19.88 1.09 21.22 2.51"/>
        <polyline class="cls-3" points="22.52 3.89 30.27 12.1 39.01 21.31 39.01 47.79"/>
        <polyline class="cls-1" points="39.01 48.74 39.01 50.69 37.07 50.69"/>
        <line class="cls-4" x1="35.26" y1="50.69" x2="3.6" y2="50.69"/>
        <polygon class="cls-1" points="27.63 14.6 19.88 6.4 12.14 14.6 4.39 22.76 4.39 47.04 35.37 47.04 35.37 22.76 27.63 14.6"/>
        <circle class="cls-1" cx="19.78" cy="32.57" r="4.33"/>
        <line class="cls-1" x1="27.77" y1="25.67" x2="25.29" y2="28.68"/>
        <line class="cls-1" x1="11.75" y1="25.67" x2="14.23" y2="28.68"/>
        <line class="cls-1" x1="19.79" y1="22.08" x2="19.77" y2="26.19"/>
        <line class="cls-1" x1="9.02" y1="32.83" x2="13.13" y2="32.85"/>
        <line class="cls-1" x1="26.63" y1="32.83" x2="30.74" y2="32.85"/>
        <polyline class="cls-1" points="21.52 36.59 21.52 40.92 18.04 40.92 18.04 36.59"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1d1d1b',
    },
  },
}
</script>

<style scoped>
.cls-2 {
  stroke-dasharray:3.81 1.9;
}
.cls-3 {
  stroke-dasharray:3.81 1.9;
}
.cls-4 {
  stroke-dasharray:3.62 1.81;
}
</style>
