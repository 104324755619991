<template>
  <vue-final-modal
    v-bind="$attrs"
    class="bg-gray-100"
    classes="modal-container"
    :content-class="`modal-content ${dynClass}`"
    @update:modelValue="updateModelValue"
  >
    <span
      :class="{
        'modal__title': true,
        'text-black': size === 'full',
      }">
      <slot name="title"></slot>
    </span>
    <div v-if="status === 'loading'" class="modal__content">
      <div class="relative" style="height: calc(100% - 2rem);"><BaseLoader /></div>
    </div>
    <div v-if="status === 'success' && message" class="modal__content flex flex-col justify-center items-center">
      <p class="text-center text-4xl mb-2">
        <span>{{ message }}</span>
        <svg class="checkmark inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
        </svg>
      </p>
    </div>
    <div v-if="status === 'error' && message" class="modal__content text-4xl flex justify-center items-center text-red-500">
      {{ message }}
    </div>
    <div v-show="status === 'ready'" class="modal__content">
      <slot></slot>
    </div>
    <div class="modal__action" v-if="status === 'error'">
      <slot name="actions">
        <BaseButton @click="$emit('cancel', close)">{{ $t('Shared.close') }}</BaseButton>
        <BaseButton primary @click="$emit('back')">{{ $t('Shared.try again') }}</BaseButton>
      </slot>
    </div>
    <div class="modal__action" v-if="status === 'ready'">
      <slot name="actions">
        <BaseButton @click="$emit('cancel', close)">{{ $t('Shared.cancel') }}</BaseButton>
        <BaseButton primary @click="$emit('confirm', close)">{{ confirmLabel }}</BaseButton>
      </slot>
    </div>
    <BaseButton
      :layout="'icon-only'"
      :icon="'BaseClose'"
      :class="{
        'modal__close': true,
        'text-black': size === 'full',
      }"

      @click="close"
    ></BaseButton>
  </vue-final-modal>
</template>

<script>
import BaseButton from '@/components/shared/BaseButton'
import BaseLoader from './BaseLoader.vue'
import { sleep } from '@/composables/utils'
export default {
  name: 'VModal',
  inheritAttrs: false,
  emits: ['update:modelValue'],
  components: {
    BaseButton,
    BaseLoader,
  },
  props: {
    confirmLabel: {
      type: String,
      default: 'ok',
    },
    size: {
      type: String,
      default: 'md',
    },
    status: {
      type: String,
      default: 'ready', // 'closed' || 'loading' || 'ready' || 'success' || 'error'
    },
    message: {
      type: String,
      default: '',
    },
  },
  computed: {
    dynClass () {
      if (this.size === 'md') return 'bg-gray-100 min-w-90 md:min-w-1/2 lg:min-w-1/4'
      if (this.size === 'lg') return 'bg-gray-100 min-w-90 lg:min-w-1/2 h-70vh'
      if (this.size === 'xl') return 'bg-gray-100 min-w-90 lg:min-w-3/4 h-90vh'
      if (this.size === 'full') return 'bg-gray-100 min-w-full min-h-full'
      else return ''
    },
  },
  methods: {
    updateModelValue (val) {
      this.$emit('update:modelValue', val)
    },
    close () {
      this.$emit('update:modelValue', false)
    },
  },
  watch: {
    async status (val) {
      console.log(val, this.message)
      if (val === 'success') {
        await sleep(1500)
        this.close()
      }
    },
  },
}
</script>

<style scoped>
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  /* border: 1px solid #e2e8f0; */
  border-radius: 0.25rem;
  /* background: #fff; */
  max-width: 30%;
}
.modal__title {
  margin: -4px 0 .5rem 0;
  padding-bottom: 4px;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 400;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem 0;
}
.modal__content > * {
  display: block;
  margin-bottom: 1rem;
}
.modal__action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}
.modal__action button {
  margin-bottom: 0;
}
.modal__action > *:not(:last-child) {
  margin-right: 1rem;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.25rem !important;
}
.dark-mode div ::v-deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 0 0 0 1rem;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
</style>
