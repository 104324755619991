export const actions = {
  namespaced: true,
  state: {
    all: [],
    hiddenStandardActions: [],
  },
  mutations: {
    addAction (state, payload) {
      state.all.push(payload)
    },
    deleteAll (state) {
      state.all = []
    },
    updateAction (state, payload) {
      const index = state.all.findIndex(item => item.id === payload.id)
      for (const key in payload.data) {
        state.all[index][key] = payload.data[key]
      }
      console.log(state.all[index])
    },
    setActionAccepted (state, payload) {
      const index = state.all.findIndex(item => item.id === payload.id)
      state.all[index].isAccepted = payload.value
    },
    setActionisParked (state, payload) {
      const index = state.all.findIndex(item => item.id === payload.id)
      state.all[index].isParked = payload.value
    },
    addHiddenStandardAction (state, id) {
      state.hiddenStandardActions.push(id)
    },
  },
  actions: {
    create () {},
    read () {},
    update () {},
    delete () {},
    addHiddenStandardAction ({ commit }, id) {
      commit('addHiddenStandardAction', id)
    },
  },
}
