<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27.42 43.96"
    fill="none" :stroke="color" stroke-miterlimit="10" stroke-width="1.5px"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_3" data-name="Layer 3">
        <line x1="13.71" y1="15.45" x2="13.71" y2="43.96"/>
        <polygon points="13.71 1.44 0.87 22.49 7.29 33.61 20.13 33.61 26.55 22.49 13.71 1.44"/>
        <polyline points="18.48 22.39 13.71 30.65 8.94 22.39"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1d1d1b',
    },
  },
}
</script>

<style scoped></style>
