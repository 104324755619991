import messages from './main'
import languages from './languages'
// const languageKeys = ['no_NO', 'da_DK', 'sv_SE', 'en_US']
const iterator = (refObj, checkObj, replace) => {
  for (const key in refObj) {
    if (typeof refObj[key] === 'object' && !Array.isArray(refObj[key])) {
      if (checkObj[key] === undefined) {
        checkObj[key] = {}
      }
      iterator(refObj[key], checkObj[key])
    } else if (typeof refObj[key] === 'string' && checkObj[key] === undefined) {
      // checkObj[key] = replace ? refObj[key] : ''
      checkObj[key] = refObj[key]
    }
  }
}
export default (dbObj) => {
  for (let i = 0; i < languages.length; i++) {
    const lang = languages[i].value
    if (dbObj[lang] === undefined) dbObj[lang] = {}
    const dbLang = dbObj[lang]
    const refLang = messages.no_NO
    iterator(refLang, dbLang, lang === 'no_NO')
  }
}
