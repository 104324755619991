<template>
  <div>
    <label :for="label" class="hidden block text-md font-medium text-gray-700">
      {{ label }}
    </label>
    <div class="mt-1">
      <textarea
        id="id"
        :name="label"
        @input="$emit('update:modelValue', $event.target.value)"
        :rows="rows"
        class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-md border border-black bg-transparent"
        :class="readonly ? 'bg-gray-100 text-gray-500' : ''"
        :placeholder="label"
        :readonly="readonly"
        :value="modelValue"
      />
    </div>
    <p v-if="hint" class="mt-2 text-sm text-gray-500">
      {{ hint }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: null,
    },
    rows: {
      type: Number,
      default: 4,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
}
</script>
