<template>
  <BaseModal
    :modelValue="modelValue"
    :size="'lg'"
    @update:modelValue="updateModelValue"
    @cancel="closeModal"
    @keyup.enter="$emit('create', project)"
  >
    <template v-slot:title>
      {{ $t('Projects.New project popup.Title') }}
    </template>
    <div class="grid grid-cols-12 mb-5 gap-4">
      <BaseInput
        id="new-project-title"
        data-cy="newProjectTitle"
        v-model="project.title"
        :label="$t('Projects.New project popup.Label01')"
        :focus="true"
        class="col-span-8"
      />
      <BaseInput
        id="new-project-number"
        v-model="project.projectNumber"
        :label="$t('Projects.New project popup.Label03')"
        ref="projectNumber"
        class="col-span-4"
      />
    </div>
    <BaseSelect
      id="project-group-select"
      v-model="project.folderId"
      :options="groups"
      :label="$t('Projects.New project popup.Label04')"
      :placeholder="$t('Projects.New project popup.Label04')"
      class="mb-5"
      :customArrow="true"
    />
    <BaseInput
      v-if="project.folderId === 'new'"
      id="new-project-group"
      v-model="project.folderTitle"
      :label="$t('Projects.New project popup.Placeholder01')"
      class="mb-5"
    />
    <BaseSelect
      id="project-country-select"
      v-model="project.countryId"
      :options="countryOptions"
      :label="$t('Projects.New project popup.Select_country_label')"
      :placeholder="$t('Projects.New project popup.Select_country_placeholder')"
      class="mb-5"
      :customArrow="true"
    />
    <div
      class="mb-5"
      v-if="optionsCollections && optionsCollections.length"
    >
      <input
        type="checkbox"
        id="new-project-collection"
        name="new-project-collection"
        v-model="addCollection"
        class="mr-2"
      >
      <label for="new-project-collection">
        {{ $t('Projects.New project popup.Label06') }}
      </label>
    </div>
    <BaseSelect
      v-if="addCollection && optionsCollections.length"
      id="project-collection-select"
      v-model="project.collectionId"
      :options="optionsCollections"
      :label="$t('Projects.New project popup.Label07')"
      :placeholder="$t('Projects.New project popup.Label07')"
      class="mb-5"
      :customArrow="true"
    />
    <BaseTextarea
      id="new-project-description"
      data-cy="newProjectDescription"
      v-model="project.description"
      :label="$t('Projects.New project popup.Label02')"
      class="mb-5"
    />
    <template v-slot:actions>
      <BaseButton @click="closeModal">
        {{ $t('Projects.New project popup.Button01') }}
      </BaseButton>
      <BaseButton
        id="create-project-button"
        primary
        @click="$emit('create', project)"
      >
        {{ $t('Projects.New project popup.Button02') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/shared/BaseModal'
import BaseButton from '@/components/shared/BaseButton'
import BaseTextarea from '@/components/shared/BaseTextarea'
import BaseInput from '@/components/shared/BaseInput'
import BaseSelect from '@/components/shared/BaseSelect'
import findActionCollections from '@/composables/actionCollections/findActionCollections'
import getAvailableCountries from '@/composables/countries/getAvailableCountries'

export default {
  components: {
    BaseModal,
    BaseButton,
    BaseInput,
    BaseTextarea,
    BaseSelect,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:modelValue', 'create'],
  data () {
    return {
      showModal: false,
      project: {
        title: '',
        description: '',
        projectNumber: null,
        folderId: null,
        folderTitle: '',
        collectionId: null,
        countryId: null,
      },
      groups: [
        { value: 'new', label: this.$t('Projects.New project popup.Label05') },
      ],
      countryOptions: [],
      addCollection: false,
    }
  },
  computed: {
    optionsCollections () {
      console.log('optionsCollections computed', this.$store.getters['action-collections/list'])
      const options = this.$store.getters['action-collections/list']
        .map(collection => ({
          label: collection.title,
          value: collection.id,
        }))
      return options
    },
  },
  methods: {
    updateModelValue (val) {
      this.$emit('update:modelValue', val)
    },
    closeModal () {
      this.$emit('update:modelValue', false)
      // close()
    },
  },
  watch: {
    'project.countryId': async function (newCountryId, oldCountryId) {
      if (newCountryId !== oldCountryId) {
        this.collectionId = null
        const collections = await findActionCollections(false, newCountryId)
        console.log('Country id changed, collections: ', collections)
        // Code to execute when countryId changes
      }
    },
    async modelValue (newValue) {
      if (newValue) {
        await this.$store.dispatch('compass-folder/find')
        const countries = await getAvailableCountries()
        this.countryOptions = countries.map(country => ({ label: country.native, value: country.id }))
        this.countryOptions.push({ value: null, label: this.$t('Projects.New project popup.country_select_no_country') })
        const folders = this.$store.getters['compass-folder/list']
        this.groups = [{ value: 'new', label: this.$t('Projects.New project popup.Label05') }]
        this.groups = this.groups.concat(folders.map(folder => ({ value: folder.id, label: folder.title })))
        this.project = {
          title: '',
          description: '',
          projectNumber: null,
          folderId: null,
          folderTitle: '',
          collectionId: null,
          countryId: null,
        }
      }
    },
    addCollection (newValue) {
      if (newValue && this.optionsCollections.length) {
        this.project.collectionId = this.optionsCollections[0].value
      } else {
        this.project.collectionId = null
      }
    },
  },
  mounted () {
    findActionCollections()
    console.log('modal mounted')
  },
}
</script>

<style scoped>
button:not(:last-child) {
  margin-right: 1rem;
}
</style>
