<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 45.91 41.36"
    fill="none" :stroke="color" stroke-miterlimit="10" stroke-width="1.5px"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_3" data-name="Layer 3">
        <path d="M23,40.44S45.16,24.59,45.16,12.12A11.39,11.39,0,0,0,22.94,8.6h0A11.37,11.37,0,0,0,.75,12.12C.75,24.59,23,40.44,23,40.44Z"/>
        <line x1="16.56" y1="22.59" x2="29.35" y2="22.59"/>
        <line x1="22.96" y1="28.99" x2="22.96" y2="16.2"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1d1d1b',
    },
  },
}
</script>

<style scoped>

</style>
