<template>
  <component
    v-if="icon"
    :id="id"
    :is="componentName"
    :key="icon"
    :color="color"
    :strokeWidth="strokeWidth"
    :size="size"
    ref="target-icon"
    preserveAspectRatio="xMidYMid meet"
    class="flex-shrink-0"
    :class="`icon ${size} text-${color}`"
    :active="active"
  />
  <div v-else :class="`icon icon-placeholder ${size}`">
  </div>
</template>

<script>
/**
 * Props
 * style: 'outline'|'solid'|'duotone'
 * size: 'sm'|''md'|'lg'
 */
import { defineAsyncComponent } from 'vue'

export default {
  name: 'icon',
  props: {
    id: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
    },
    strokeWidth: {
      type: Number,
      default: 1,
    },
    border: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'gray-800',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      sizeMap: {
        sm: 1,
        md: 1.5,
        lg: 2,
        xl: 3,
      },
    }
  },
  computed: {
    componentName () {
      return defineAsyncComponent(() => import('./icons/' + this.icon))
    },
    width () {
      const factor = this.sizeMap[this.size]
      return factor * 16
    },
    height () {
      const factor = this.sizeMap[this.size]
      return factor * 16
    },
  },
}
</script>

<style scoped>
.icon-placeholder {
  display: inline-block;
  border-radius: 50%;
  background: grey
}
.icon.sm {
    width: 1rem;
    height: 1rem;
}
.icon.md {
    width: 1.5rem;
    height: 1.5rem;
}
.icon.lg {
    width: 2rem;
    height: 2rem;
}
.icon.xl {
    width: 4rem;
    height: 4rem;
}
.icon.xxl {
    width: 6rem;
    height: 6rem;
}
.icon.full {
    width: 100%;
    height: 100%;
}
</style>
