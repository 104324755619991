import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class EmailLogin extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.

  static modelName = 'EmailLogin'
  // Define default properties here
  static instanceDefaults () {
    return {}
  }
}
const servicePath = 'email-login'
const servicePlugin = makeServicePlugin({
  Model: EmailLogin,
  service: feathersClient.service(servicePath),
  servicePath,
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
  },
  after: {
    all: [],
    find: [],
  },
  error: {
    all: [],
    find: [],
  },
})

export default servicePlugin
