import './assets/css/fonts.css'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { store } from './store'
import { FeathersVuex } from './feathers-client'
import './index.css'
import panZoom from 'vue-panzoom'
import VueFinalModal from 'vue-final-modal'
import { createI18n } from 'vue-i18n'
import messages from './i18n/main'
import mitt from 'mitt'
import TemaHelse from './components/shared/icons/TemaHelse.vue'
import TemaAvfall from './components/shared/icons/TemaAvfall.vue'
import TemaBlaaGroenFaktor from './components/shared/icons/TemaBlaaGroenFaktor.vue'
import TemaEnergi from './components/shared/icons/TemaEnergi.vue'
import TemaEnergismartDesign from './components/shared/icons/TemaEnergismartDesign.vue'
import TemaFunksjonOgBruk from './components/shared/icons/TemaFunksjonOgBruk.vue'
import TemaIdentitetOgLikeverd from './components/shared/icons/TemaIdentitetOgLikeverd.vue'
import TemaKlimatilpasning from './components/shared/icons/TemaKlimatilpasning.vue'
import TemaKundensForretning from './components/shared/icons/TemaKundensForretning.vue'
import TemaMateriale from './components/shared/icons/TemaMateriale.vue'
import TemaMiljosertifisering from './components/shared/icons/TemaMiljosertifisering.vue'
import TemaTransportOgMobilitet from './components/shared/icons/TemaTransportOgMobilitet.vue'
import TemaTrygghetOgSikkerhet from './components/shared/icons/TemaTrygghetOgSikkerhet.vue'
import TemaGroentstrukturOgBiodiversitet from './components/shared/icons/TemaGroentstrukturOgBiodiversitet.vue'

const i18n = createI18n({
  locale: 'no_NO',
  fallbackLocale: 'no_NO',
  messages,
})

// eslint-disable-next-line no-extend-native
String.prototype.capitalizeFirstLetter = function () {
  return this[0].toUpperCase() + this.slice(1)
}

const emitter = mitt()
const app = createApp(App)
app.component('TemaHelse', TemaHelse)
app.component('TemaAvfall', TemaAvfall)
app.component('TemaBlaaGroenFaktor', TemaBlaaGroenFaktor)
app.component('TemaEnergi', TemaEnergi)
app.component('TemaEnergismartDesign', TemaEnergismartDesign)
app.component('TemaFunksjonOgBruk', TemaFunksjonOgBruk)
app.component('TemaIdentitetOgLikeverd', TemaIdentitetOgLikeverd)
app.component('TemaKlimatilpasning', TemaKlimatilpasning)
app.component('TemaKundensForretning', TemaKundensForretning)
app.component('TemaMateriale', TemaMateriale)
app.component('TemaMiljosertifisering', TemaMiljosertifisering)
app.component('TemaTransportOgMobilitet', TemaTransportOgMobilitet)
app.component('TemaTrygghetOgSikkerhet', TemaTrygghetOgSikkerhet)
app.component('TemaGroentstrukturOgBiodiversitet', TemaGroentstrukturOgBiodiversitet)
app.use(store)
app.use(FeathersVuex)
app.use(router)
app.use(panZoom)
app.use(i18n)
app.config.globalProperties.emitter = emitter
app.mount('#app')
app.use(VueFinalModal())
