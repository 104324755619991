export const defaultWorkshopSteps = (t) => {
  return [
    {
      name: 'vision',
      label: t('Workshop.Steps.Step1Label'),
      description: t('Workshop.Steps.Step1Description'),
      subSteps: [],
      selectedSubSteps: [],
    },
    /* {
      name: 'visionrose',
      label: t('Workshop.Steps.Step2Label'),
      description: t('Workshop.Steps.Step2Description'),
      subSteps: [],
      selectedSubSteps: [],
    }, */
    {
      name: 'guide',
      label: t('Workshop.Steps.Step3Label'),
      description: t('Workshop.Steps.Step3Description'),
      subSteps: [
        /* { name: 'intro', label: t('Workshop.Steps.StepGuideSubStep1Label') }, */
        { name: 'omraader', label: t('Workshop.Steps.StepGuideSubStep2Label') },
        { name: 'ambition', label: t('Workshop.Steps.StepGuideSubStep4Label') },
        { name: 'tiltag', label: t('Workshop.Steps.StepGuideSubStep3Label') },
      ],
      selectedSubSteps: ['omraader', 'ambition', 'tiltag'],
    },
    {
      name: 'opsamling',
      label: t('Workshop.Steps.Step4Label'),
      description: t('Workshop.Steps.Step4Description'),
      subSteps: [],
      selectedSubSteps: [],
    },
  ]
}
export const specialOtherSubcats = {
  1001: 10002,
  1002: 10003,
  1003: 10004,
  1004: 10005,
  1005: 10006,
}
