export const goals = {
  namespaced: true,
  state: {
    all: [
      { description: 'Det er en overordnet målsetning for St. Olav VGS å ha en klar helhetlig bærekraftsprofil, som møter alle kvalitetsområder i Bygg 21 sine «10 kvalitetsprinsipper for bærekraftige bygg».', id: '0', isAccepted: true },
      { description: 'Det er viktig at bygget er bærekraftig i en helhet, og leverer bredt på kvalitetene og områdene.', id: '1', isAccepted: false },
      { description: 'Det er viktig med en helhetlig løsning, som leverer på alle bærekraftkvalitetene, fremfor suboptima-lisering på spesifikke bærekrafts- og miljøkvaliteter.', id: '2', isAccepted: false },
      { description: 'Det er en overordnet målsetting med prosjektet å sikre funksjonalitet og gode brukerkvaliteter, å sikre realisering innenfor den økonomiske rammen som er tilgjengelig, og samtidig løse våre na-sjonale og internasjonale miljømål-', id: '3', isAccepted: false },
      { description: 'Lorem ipsum dolor... ', id: '4', isAccepted: false },
    ],
  },
  mutations: {
    addGoal (state, payload) {
      console.log('commit goal', state.all)
      state.all.push(payload)
    },
    updateGoal (state, payload) {
      const index = state.all.findIndex(item => item.id === payload.id)
      state.all[index].description = payload.value
    },
    setGoalStatus (state, payload) {
      const index = state.all.findIndex(item => item.id === payload.id)
      state.all[index].isAccepted = payload.value
      state.all = [...state.all.filter(goal => goal.isAccepted), ...state.all.filter(goal => !goal.isAccepted)]
    },
  },
  actions: {
    updateGoal ({ commit }, payload) {
      commit('updateGoal', payload)
    },
    addGoal ({ commit, state }) {
      const goal = { description: '', id: state.all.length, isAccepted: false }
      commit('addGoal', goal)
      console.log('dispatch goal', state.all)
    },
  },
}
