import { store } from '@/store'
// import { getCategoryEffectRating } from './getCategoryEffectRating'
export const getCategoryVisionRating = (compassId, frameworkId, categoryId, compassUserRatingId) => {
  // if framework is 1 then get rating where categoryId match
  // if framework is 2 then calculate based on subcategorymatches
  // else get get rating where frameworksCategoryId match
  let ratingSum = 0
  if (frameworkId === 1) { // framework is link compass
    const result = store.getters['compass-categories-ratings/find']({
      query: {
        compassId,
        categoryId,
        compassUserRatingId,
      },
    })
    ratingSum += result.data[0]?.rating ? result.data[0].rating : 0
  } else {
    const result = store.getters['compass-categories-ratings/find']({
      query: {
        compassId: compassId,
        frameworksCategoryId: categoryId,
        compassUserRatingId,
      },
    })
    ratingSum += result.data[0]?.rating ? result.data[0].rating : 0
  }
  return ratingSum
}
/* else if (frameworkId === 3) { // framework is impact architecture
  // map category > frameworkCategory
  // foreach category that matches frameworkCategory add category rating to ratingSum
} */
