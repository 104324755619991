<template>
  <div :id="'chart-container-' + chartId" class="w-full relative">
    <canvas :id="chartUid" class="box-border w-full" data-cy="rosechart"></canvas>
  </div>
</template>

<script>
// to do: https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Optimizing_canvas

import debounce from 'lodash/debounce'
import chart from '../../composables/createChartPie'
import { getCategoryVisionRating } from '@/composables/ratings/getCategoryVisionRating'
import { getCategoryEffectRating } from '@/composables/ratings/getCategoryEffectRating'
import { numberToTwoDigits } from '@/composables/utils.js'
export default {
  props: {
    chartId: {
      type: String,
      required: true,
    },
    refreshChart: {
      type: Number,
      default: 0,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    canSelectCategory: {
      type: Boolean,
      default: true,
    },
    canSaveRatings: {
      type: Boolean,
      default: true,
    },
    highlightSelectedCategory: {
      type: Boolean,
      default: true,
    },
    showLabels: {
      type: Boolean,
      default: true,
    },
    showIcons: {
      type: Boolean,
      default: true,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
    compassId: {
      type: Number,
      default: null,
    },
    frameworkId: {
      type: Number,
      default: 1,
    },
    compassUserRatingId: {
      type: Number,
      default: null,
    },
    frameworkTitle: {
      type: String,
      default: null,
    },
    isExport: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      chart: null,
      // chartUidCount: 0,
      // chartUid: 'chart-canvas-' + this.chartId + 0,
      chartUid: 'chart-canvas-' + this.chartId,
      resizeObserver: null,
    }
  },
  computed: {
    categories () {
      const path = this.frameworkId === 1 ? 'categories/list' : 'frameworks-categories/list'
      const cats = this.$store.getters[path].filter(item => item.frameworkId === this.frameworkId)
      return cats
    },
    compassActions () {
      return this.$store.getters['compass-actions/list']
        .filter(item => item.compassId === this.compassId)
    },
    actionRatings () {
      return this.$store.getters['action-ratings/list']
        .filter(item => item.compassId === this.compassId)
    },
    compassCategoriesRatings () {
      return this.$store.getters['compass-categories-ratings/list']
        .filter(item => item.compassId === this.compassId)
    },
    compassSubCategories () {
      return this.$store.getters['compass-sub-categories/list']
        .filter(item => item.compassId === this.compassId)
    },
    compassVersion () {
      if (this.compassId) {
        return this.$store.getters['compasses/get'](this.compassId).version
      } else {
        return 0
      }
    },
  },
  emits: ['themeSelected'],
  methods: {
    /*
    refreshChartElement () {
      this.chartUidCount++
      this.chartUid = 'chart-canvas-' + this.chartId + this.chartUidCount
    },
    */
    async getChartData () {
      const parsedCats = []
      let oldCompassCats = []
      if (this.compassVersion === 1) {
        oldCompassCats = await this.$store.dispatch('rating-data/find', {
          query: {
            frameworkId: this.frameworkId,
            compassId: this.compassId,
            locale: this.$i18n.locale,
          },
        })
      }
      // console.log(this.$store.getters['sub-categories-defaults/list'])
      /* this.$store.getters['sub-categories-defaults/list']
        .filter(mapping => mapping.frameworksCategoryId)
        .sort((a, b) => a.subCategoryId - b.subCategoryId)
        .forEach(catMap => {
          console.log(catMap.frameworksCategoryId + ' -> ' + catMap.subCategoryId)
        }) */
      this.categories.forEach((category, i) => {
        const series1Value = oldCompassCats.length === 0 ? getCategoryEffectRating(this.compassId, this.frameworkId, category.id) : oldCompassCats.filter(cat => cat.id === category.id)[0].value
        const series2Value = this.compassUserRatingId ? getCategoryVisionRating(this.compassId, this.frameworkId, category.id, this.compassUserRatingId) : 0
        parsedCats.push({
          title: category.title,
          label: numberToTwoDigits(i + 1),
          color: category.color,
          value: series1Value.toFixed(2),
          visionValue: series2Value.toFixed(2),
          series1Label: this.$t('Shared.effect'),
          series2Label: this.$t('Shared.vision'),
          compassUserRatingId: this.compassUserRatingId,
          icon: category.icon,
          id: category.id,
        })
      })
      return parsedCats
    },
    setChartListener () {
      this.chart.onHoverCategory((catIndex) => {
      })
      if (this.canSelectCategory) {
        this.chart.onClickCategory((catId) => {
          this.$emit('themeSelected', catId)
        })
      }
      if (this.clickable) {
        this.chart.onValueUpdate((category, value) => {
          if (this.canSaveRatings) {
            this.$store.dispatch('compass-categories-ratings/create', {
              compassId: this.compassId,
              categoryId: category.id,
              rating: value > 0 ? value : 0,
              frameworkId: this.frameworkId,
              compassUserRatingId: category.compassUserRatingId,
            })
          }
          /*
          if (this.canSaveRatings) {
            if (category.ratingId) {
              this.$store.dispatch('compass-categories-ratings/patch', [category.ratingId, {
                rating: value,
              }])
            } else {
              this.$store.dispatch('compass-categories-ratings/create', {
                ratingId: category.ratingId,
                compassId: this.compassId,
                frameworkId: this.frameworkId,
                categoryId: category.id,
                rating: value,
              })
            }
          }
          */
        })
      }
    },
    async updateChartData () {
      console.log('updateChartData')
      const data = await this.getChartData()
      this.chart.updateDataValues(data)
      this.chart.resize()
    },
    async initChart (firstInit = false) {
      this.destroyChart()
      if (firstInit) this.chart = chart()
      const options = {
        canSelectCategory: this.canSelectCategory,
        rotateSelectedCategoryToTop: true,
        showSecondLayer: true,
        canRate: this.clickable,
        showLabels: this.showLabels,
        showIcons: this.showIcons,
        highlightSelectedCategory: this.highlightSelectedCategory,
        frameworkId: this.frameworkId,
        frameworkTitle: this.frameworkTitle,
      }
      if (this.isExport) {
        options.xRes = 4096
        options.yRes = 4096 + 150
      }
      const _data = await this.getChartData()
      const chartEl = document.getElementById(this.chartUid)
      this.chart.initChart(
        chartEl,
        _data,
        options,
      )
      this.setChartListener()
      this.resizeObserver = new ResizeObserver(debounce(() => {
        this.chart.resize()
      }, 150))
      const chartContainer = document.getElementById('chart-container-' + this.chartId)
      this.resizeObserver.observe(chartContainer)
      window.addEventListener('resize', debounce(this.chart.resize, 250))
      this.emitter.on('resetChart', this.chart.resetCategorySelect)
      this.emitter.on('resizeChart', debounce(this.chart.resize, 250))
      this.emitter.on('selectCategory', (index) => {
        // TODO: få setCategorySelect til at virke
        // this.chart.setCategorySelect(index)
      })
    },
    destroyChart () {
      if (this.chart) {
        if (this.resizeObserver) {
          const chartContainer = document.getElementById('chart-container-' + this.chartId)
          this.resizeObserver.unobserve(chartContainer)
          this.resizeObserver = null
        }
        window.removeEventListener('resize', debounce(this.chart.resize, 250))
        this.emitter.off('resetChart', this.chart.resetCategorySelect)
        this.emitter.off('resizeChart', debounce(this.chart.resize, 250))
        this.chart.destroyChart()
      }
    },
  },
  watch: {
    refreshChart (newValue) {
      console.log('chart refresh called')
      this.updateChartData()
    },
    categories (val) {
      console.log('categories changed')
      this.initChart(true)
    },
    isVisible (val) {
      if (val) {
        this.$nextTick(() => {
          console.log('is visible changed')
          this.initChart()
          // this.chart.resize(null, null)
        })
      }
    },
    compassActions () {
      console.log('compassActions changed')
      this.updateChartData()
    },
    compassSubCategories () {
      console.log('compassSubCategories changed')
      this.updateChartData()
    },
    compassUserRatingId () {
      console.log('compassUserRatingId changed')
      this.updateChartData()
    },
    actionRatings: {
      handler () {
        console.log('actionRatings changed')
        this.updateChartData()
      },
      deep: true,
    },
    compassCategoriesRatings: {
      handler () {
        console.log('compassCategoriesRatings changed')
        this.updateChartData()
      },
      deep: true,
    },
  },
  async mounted () {
    this.initChart(true)
    // await this.getRatings()
  },
  beforeUnmount () {
    this.destroyChart()
  },
}
</script>

<style scoped>
</style>
