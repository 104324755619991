<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36.96 47.42"
    fill="none"
    :stroke="color"
    stroke-miterlimit="10"
    stroke-width="1.5px"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_3" data-name="Layer 3">
        <polyline class="cls-1" points="18.97 40.82 0.75 40.82 0.75 0.75 28.46 0.75 28.46 28.86"/>
        <line class="cls-1" x1="10.17" y1="6.6" x2="23.93" y2="6.6"/>
        <line class="cls-1" x1="10.17" y1="9.34" x2="21.11" y2="9.34"/>
        <polyline class="cls-1" points="4.14 6.6 5.93 9.34 8.1 4.53"/>
        <line class="cls-1" x1="10.17" y1="15.27" x2="22.8" y2="15.27"/>
        <line class="cls-1" x1="10.17" y1="18.01" x2="23.93" y2="18.01"/>
        <polyline class="cls-1" points="4.14 15.27 5.93 18.01 8.1 13.2"/>
        <line class="cls-1" x1="10.17" y1="23.94" x2="21.11" y2="23.94"/>
        <line class="cls-1" x1="10.17" y1="26.68" x2="23.37" y2="26.68"/>
        <polyline class="cls-1" points="4.14 23.94 5.93 26.68 8.1 21.87"/>
        <line class="cls-1" x1="10.17" y1="32.61" x2="20.1" y2="32.61"/>
        <line class="cls-1" x1="10.17" y1="35.35" x2="18.72" y2="35.35"/>
        <polyline class="cls-1" points="4.14 32.62 5.93 35.35 8.1 30.54"/>
        <circle class="cls-1" cx="27.35" cy="37.8" r="8.86"/>
        <polyline class="cls-1" points="23.07 36.72 27.05 42 31.4 32.93"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1d1d1b',
    },
  },
}
</script>

<style scoped>

</style>
