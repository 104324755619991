<template>
  <div>
    <div v-if="loading">
      <BaseLoader />
    </div>
    <div v-else class="page">
      <transition name="fade">
        <Header v-if="showHeader" class="bg-white"/>
      </transition>
      <transition name="fade">
        <Hero v-show="showHero"/>
      </transition>
    </div>
    <div class="hidden">{{ version }} / {{ hash }}</div>
  </div>
</template>

<script>
import Header from '@/components/shared/header/Header'
import Hero from '@/components/shared/Hero'
import { sleep } from '@/composables/utils'
import BaseLoader from '../components/shared/BaseLoader.vue'

export default {
  components: {
    Header,
    Hero,
    BaseLoader,
  },
  data () {
    return {
      showHeader: false,
      showHero: false,
      loading: true,
      version: process.env.VUE_APP_VERSION,
      hash: process.env.VUE_APP_GIT_HASH,
    }
  },
  created () {},
  async mounted () {
    this.loading = false
    this.showHero = true
    await sleep(1000)
    this.showHeader = true
  },
}
</script>

<style scoped>
</style>
