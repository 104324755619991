<template>
  <div>
    <BaseLoader v-if="loading && !error"/>
    <BaseError v-if="error" :errorMessage="error" />
    <div v-if="!loading && !error" class="page">
      <transition name="fade">
        <Header v-if="showHeader" class="bg-white"/>
      </transition>
        <div class="mt-52 mb-20 relative">
          <div class="content-wrapper relative">
            <transition name="fade">
              <h1 v-if="showTitle" class="page-title sm:space-x-8 flex flex-col sm:flex-row sm:items-end">
                <span>{{ $t('Projects.Project list view.Title') }}</span>
                <span class="pb-2">
                  <BaseButton id="new-project-button" :layout="'icon-and-text'" :icon="'BasePlus'" outline @click="showAddProjectModal = true" class="btn">
                    {{ $t('Projects.Project list view.Button01') }}
                  </BaseButton>
                </span>
              </h1>
            </transition>
            <transition name="fade">
              <div v-if="showContent" class="">
                <transition name="fade">
                  <div>
                    <div class="w-full flex gap-4 mb-4">
                      <BaseInput
                        id="project-list-search"
                        class="flex-grow"
                        data-cy=""
                        v-model="searchQuery"
                        :label="$t('Shared.search')"
                        :focus="true"
                        :iconName="'BaseSearch'"
                      />
                      <BaseSelect id="project-group-select" class="flex-grow" v-model="selectedFolder" :options="folderOptions" :label="$t('Projects.New project popup.Label04')" :placeholder="$t('Shared.choose') + ' ' + $t('Projects.New project popup.Label04')" :customArrow="true" />
                    </div>
                    <ProjectsList v-if="searchedCompasses.length > 0" :projects="searchedCompasses"/>
                    <p v-else class="italic">{{ $t('Projects.Project list view.MessageNoProjects') }}</p>
                  </div>
                </transition>
              </div>
            </transition>
          </div>
        </div>
        <ModalAddProject v-model="showAddProjectModal" @create="createProject"/>
    </div>
  </div>
</template>

<script>
import Header from '@/components/shared/header/Header'
import ProjectsList from '@/components/projects/ProjectsList'
import BaseButton from '../components/shared/BaseButton.vue'
import ModalAddProject from '@/components/projects/ModalAddProject'
import { models, makeFindMixin } from '@feathersjs/vuex'
import { sleep } from '@/composables/utils'
import BaseLoader from '../components/shared/BaseLoader.vue'
import BaseError from '../components/shared/BaseError.vue'
import BaseInput from '@/components/shared/BaseInput'
import BaseSelect from '@/components/shared/BaseSelect'
import createCompassActionsFromCollection from '@/composables/actions/createCompassActionsFromCollection'

export default {
  components: {
    Header,
    ProjectsList,
    BaseButton,
    ModalAddProject,
    BaseLoader,
    BaseError,
    BaseInput,
    BaseSelect,
  },
  data () {
    return {
      error: null,
      loading: true,
      showHeader: false,
      showTitle: false,
      showContent: false,
      showAddProjectModal: false,
      searchQuery: '',
      selectedFolder: 'all',
      folderOptions: [{ value: 'all', label: this.$t('Shared.all') }],
    }
  },
  computed: {
    compassesParams () {
      return {}
    },
    compassesFetchParams () {
      return {}
    },
    filteredCompasses () {
      if (this.selectedFolder === 'all' || this.selectedFolder === null) {
        return this.compasses
      }
      if (this.selectedFolder === 'outside') {
        return this.compasses.filter(compass => !compass.compassFolderId)
      }
      return this.compasses.filter(compass => compass.compassFolderId === this.selectedFolder)
    },
    searchedCompasses () {
      if (this.searchQuery === '') {
        return this.filteredCompasses
      }
      const foldersWithQueryInTitle = this.$store.getters['compass-folder/list'].filter(folder => folder.title.toLowerCase().includes(this.searchQuery.toLowerCase()))
      return this.filteredCompasses.filter(compass => (
        compass.title.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        compass.description.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        foldersWithQueryInTitle.some(folder => folder.id === compass.compassFolderId)
      ))
    },
  },
  mixins: [makeFindMixin({ service: 'compasses' })],
  methods: {
    async addCollection (actionCollectionId, compassId) {
      await createCompassActionsFromCollection(actionCollectionId, compassId)
    },
    async createProject (project) {
      try {
        this.loading = true
        console.log('create project', project)
        if (project.folderId === 'new') {
          const newProjectFolder = await new models.api.CompassFolder()
          newProjectFolder.title = project.folderTitle
          const _createdFolder = await newProjectFolder.create()
          project.folderId = _createdFolder.id
        }
        const newCompass = await new models.api.Compass()
        newCompass.title = project.title
        newCompass.description = project.description
        newCompass.projectNumber = project.projectNumber
        newCompass.compassFolderId = project.folderId
        newCompass.countryId = project.countryId
        // const _createdCompass = await newCompass.create()
        const _createdCompass = await this.$store.dispatch('compasses/create', [newCompass, { query: { collectionId: project.collectionId } }])
        if (project.collectionId) {
          console.log('add collection')
          await this.addCollection(project.collectionId, _createdCompass.id)
        }
        console.log('created compass redirecting to new project', _createdCompass)
        this.$router.push(`/projekt/${_createdCompass.id}`)
      } catch (e) {
        console.log(e)
        this.loading = false
      }
      // this.$store.state
    },
  },
  async mounted () {
    try {
      await this.$store.dispatch('refreshStandardActions', { isEnabled: true })
      await this.$store.dispatch('compass-folder/find')
      const folders = this.$store.getters['compass-folder/list'].map(folder => ({ value: folder.id, label: folder.title }))
      const allFoldersOption = { value: 'all', label: this.$t('Shared.all') + ' ' + this.$t('Shared.folders') }
      const outsideFolderOption = { value: 'outside', label: this.$t('Shared.outside') + ' ' + this.$t('Shared.folder') }
      this.folderOptions = [allFoldersOption, ...folders, outsideFolderOption]
      this.selectedFolder = null
      await this.$store.dispatch('compasses/find')
      await this.$store.dispatch('loadCompassData', this.$store.state.compasses.ids)
      this.loading = false
      this.showTitle = true
      await sleep(700)
      this.showHeader = true
      await sleep(200)
      this.showContent = true
      console.log(this.compasses)
    } catch (error) {
      console.log(error)
      this.loading = false
      this.error = 'Der opstod en fejl'
      /*
      if (error.code === 401) {
        await this.$store.dispatch('auth/logout')
        this.$router.push('/login')
      } else {
        this.error = 'Der opstod en fejl'
      }
      */
    }
  },
}
</script>

<style scoped>
</style>
