<template>
  <div class="logo-container relative self-stretch">
    <!-- <svg fill="currentColor" class="logo-illustration" version="1.1" id="Lag_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1104 778" xml:space="preserve">
      <polygon class="letter-l" style="fill-rule:evenodd;clip-rule:evenodd;" points="365,292 214.1,204.8 482.8,49.7 404.3,4.3
        68.7,198.1 298.2,330.6 "></polygon>
      <polygon class="letter-n" style="fill-rule:evenodd;clip-rule:evenodd;" points="767.2,357 823.7,389.6 986,295.9
        935.1,453.9 986.3,483.5 1102.8,416.2 1102.8,351 1027.6,394.4 1077.8,245.7 1018.9,211.7 "></polygon>
      <polygon class="letter-i" style="fill-rule:evenodd;clip-rule:evenodd;" points="876.1,0.4 624.4,145.7 683.3,179.7
        935,34.4 "></polygon>
      <polygon class="letter-k" style="fill-rule:evenodd;clip-rule:evenodd;" points="0.2,728 78.7,773.4 169.4,721 250.5,717.7
        230.8,776.5 341.1,776.5 363,713 625.9,701.8 534.7,649.2 268.9,663.6 414.3,579.7 335.7,534.3 "></polygon>
    </svg> -->
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 454 73">
      <polygon class="cls-1" points="18.28 11.36 7.89 11.36 7.89 56.03 38.25 56.03 38.25 47.14 18.28 47.14 18.28 11.36"/>
      <polygon class="cls-1" points="43.46 11.36 43.46 33.7 43.46 56.03 48.66 56.03 53.85 56.03 53.85 33.7 53.85 11.36 48.66 11.36 43.46 11.36"/>
      <polygon class="cls-1" points="90.3 38.67 72.01 11.36 61.62 11.36 61.62 56.03 71.57 56.03 71.57 27.23 91.23 56.03 100.25 56.03 100.25 11.36 90.3 11.36 90.3 38.67"/>
      <polygon class="cls-1" points="146.4 11.36 134.33 11.36 118.41 30.71 118.41 11.36 108.02 11.36 108.02 56.03 118.41 56.03 118.41 43.96 123.38 38.18 135.39 56.03 147.46 56.03 130.29 30.15 146.4 11.36"/>
      <polygon class="cls-1" points="199.87 11.36 192.71 11.36 171.94 33.95 171.94 11.36 165.96 11.36 165.96 56.03 171.94 56.03 171.94 41.41 177.78 35.13 193.71 56.03 200.74 56.03 181.77 30.84 199.87 11.36"/>
      <path class="cls-1" d="M216.41,22.87c-8.9,0-15.12,6.84-15.12,16.92s6.22,16.92,15.12,16.92,15.12-6.84,15.12-16.92-6.22-16.92-15.12-16.92ZM216.41,51.93c-5.72,0-9.46-4.73-9.46-12.13s3.73-12.19,9.46-12.19,9.46,4.73,9.46,12.19-3.73,12.13-9.46,12.13Z"/>
      <path class="cls-1" d="M272.45,22.87c-5.1,0-8.58,2.86-10.39,5.54-1.62-3.36-4.67-5.54-9.02-5.54s-7.65,2.36-9.39,5.04v-4.42h-5.6v32.54h5.6v-19.47c0-5.29,3.98-8.46,7.71-8.46,4.6,0,6.47,3.42,6.47,7.96v19.97h5.6v-19.47c0-5.29,3.98-8.46,7.71-8.46,4.6,0,6.47,3.42,6.47,7.96v19.97h5.6v-21.46c0-6.91-4.04-11.7-10.76-11.7Z"/>
      <path class="cls-1" d="M306.97,22.87c-4.54,0-8.15,2.18-10.08,5.35v-4.73h-5.6v43.73h5.6v-15.93c1.93,3.23,5.54,5.41,10.08,5.41,9.02,0,14.18-7.71,14.18-16.92s-5.16-16.92-14.18-16.92ZM305.97,51.8c-4.6,0-9.21-3.36-9.21-10.82v-2.3c0-7.59,4.6-10.89,9.21-10.89,6.1,0,9.52,4.98,9.52,12.01s-3.42,12.01-9.52,12.01Z"/>
      <path class="cls-1" d="M351.73,49.25v-15.86c0-6.97-4.48-10.51-11.76-10.51-6.66,0-11.45,3.79-13,9.08l5.29,1.37c1-3.61,3.42-5.54,7.53-5.54,4.35,0,6.41,2.11,6.41,5.85v1.37l-7.9,1.62c-7.28,1.49-12.32,4.17-12.32,10.64,0,5.85,4.79,9.33,10.7,9.33,4.42,0,8.03-1.68,10.02-4.23.68,3.11,3.55,4.29,8.52,3.67v-4.48c-2.18.5-3.48-.19-3.48-2.3ZM346.19,44.96c0,4.17-4.04,7.09-8.83,7.09-3.55,0-5.85-1.87-5.85-5.04,0-3.92,3.36-5.29,8.34-6.28l6.34-1.37v5.6Z"/>
      <path class="cls-1" d="M373.42,36.87c-4.54-1-7.4-1.56-7.4-4.6s2.49-4.79,6.22-4.79c4.11,0,7.15,2.05,8.83,5.29l3.98-3.17c-1.93-3.86-6.72-6.72-12.57-6.72-6.91,0-11.94,4.04-11.94,9.83,0,6.66,5.6,7.84,10.95,9.02,5.35,1.12,8.46,1.62,8.46,5.16s-3.36,5.16-7.15,5.16c-4.23,0-8.21-2.3-9.95-6.1l-4.35,3.17c2.49,5.04,8.4,7.59,14.31,7.59,6.97,0,12.63-4.11,12.63-10.33,0-7.09-6.41-8.21-12.01-9.52Z"/>
      <path class="cls-1" d="M403.94,36.87c-4.54-1-7.4-1.56-7.4-4.6s2.49-4.79,6.22-4.79c4.11,0,7.15,2.05,8.83,5.29l3.98-3.17c-1.93-3.86-6.72-6.72-12.57-6.72-6.91,0-11.94,4.04-11.94,9.83,0,6.66,5.6,7.84,10.95,9.02,5.35,1.12,8.46,1.62,8.46,5.16s-3.36,5.16-7.15,5.16c-4.23,0-8.21-2.3-9.95-6.1l-4.35,3.17c2.49,5.04,8.4,7.59,14.31,7.59,6.97,0,12.63-4.11,12.63-10.33,0-7.09-6.41-8.21-12.01-9.52Z"/>
      <path class="cls-1" d="M434.17,10.68c-7.34,0-13.38,5.66-13.38,13.37s6.03,13.38,13.38,13.38,13.38-5.6,13.38-13.38-5.97-13.37-13.38-13.37ZM434.17,35.63c-6.35,0-11.38-4.98-11.38-11.57s5.04-11.57,11.38-11.57,11.38,4.91,11.38,11.57-5.04,11.57-11.38,11.57Z"/>
      <path class="cls-1" d="M439.96,21.32c0-2.67-1.93-4.17-5.16-4.17h-6.22v13.44h3.11v-4.85h2.18l3.17,4.85h3.48l-3.67-5.54c1.37-.44,3.11-1.49,3.11-3.73ZM434.36,23.37h-2.67v-3.73h2.67c1.43,0,2.36.62,2.36,1.87,0,1.31-.93,1.87-2.36,1.87Z"/>
    </svg>
    <!-- <span class="absolute -top-2 left-14 text-2xl">Kompass</span> -->
  </div>
</template>

<style scoped>
.logo-container {
  position: relative;
  overflow: hidden;
  /* width: 68px; */
  width: 220px;
}
</style>
