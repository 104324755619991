<template>
  <div
    ref="coverImage"
    class="w-full h-192 bg-gray-200 bg-cover"
    :style="'background-image: url(' + coverUrl + ');'"
  >
  </div>
</template>

<script>
import cover from '../../assets/images/page_about_cover.jpg'
export default {
  data () {
    return {
      coverUrl: cover,
    }
  },
  created () {
    document.createElement('img').setAttribute('src', cover)
  },
  mounted () {
    this.$refs.coverImage.style.height = '100vh'
  },
}
</script>

<style scoped>

</style>
