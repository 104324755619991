// https://blog.maximeheckel.com/posts/the-physics-behind-spring-animations/
const getNextPosition = (stiffness, mass, damping, currentX, currentVelocity) => {
  /* Spring Length, set to 1 for simplicity */
  const springLength = 0

  /* Object position and velocity. */
  let x = currentX
  let v = currentVelocity
  /* Spring stiffness, in kg / s^2 */
  const k = -stiffness

  /* Damping constant, in kg / s */
  const d = -damping

  /* Framerate: we want 60 fps hence the framerate here is at 1/60 */
  const frameRate = 1 / 100

  const Fspring = k * (x - springLength)
  const Fdamping = d * v

  const a = (Fspring + Fdamping) / mass
  v += a * frameRate
  x += v * frameRate

  /**
   * positions is an array of number where each number
   * represents the position of the object in a spring
   * motion at a specific frame
   *
   * We use this array to plot all the position of the
   * object for 10 seconds.
   */
  return { x, v }
}

export default getNextPosition
