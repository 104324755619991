<template>
  <div class="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center">
    <h3 class="text-red-500">{{errorMessage}}</h3>
  </div>
</template>

<script>
export default {
  props: {
    errorMessage: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
