<template>
  <div></div>
</template>

<script>

export default {
  created () {
    this.logout()
  },
  methods: {
    async logout () {
      await this.$store.dispatch('auth/logout')
      location.href = '/'
    },
  },
}
</script>

<style>

</style>
