<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56.83 57"
    fill="none" :stroke="color" stroke-miterlimit="10"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_3" data-name="Layer 3">
        <polyline class="cls-1" points="5.67 51.27 14.35 40.58 19.35 45.03 24.51 34.47 28.65 38.5 39.63 30.19 45.25 16.35"/>
        <polyline class="cls-1" points="54.33 51.27 5.67 51.27 5.67 2.6"/>
        <polyline class="cls-1" points="47.83 45.95 55.5 51.17 47.83 56.38"/>
        <polyline class="cls-1" points="0.62 9 5.83 1.33 11.05 9"/>
        <line class="cls-2" x1="5.5" y1="44.75" x2="45.25" y2="44.75"/>
        <line class="cls-2" x1="5.5" y1="16.35" x2="45.25" y2="16.35"/>
        <line class="cls-2" x1="5.5" y1="23.45" x2="45.25" y2="23.45"/>
        <line class="cls-2" x1="5.5" y1="30.55" x2="45.25" y2="30.55"/>
        <line class="cls-2" x1="5.5" y1="37.65" x2="45.25" y2="37.65"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1d1d1b',
    },
  },
}
</script>

<style scoped>
.cls-1{stroke-width:1.5px;}.cls-2{stroke-width:0.5px;stroke-dasharray:2 2;}
</style>
