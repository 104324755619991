<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 58.87 41.17"
    fill="none"
    :stroke="color"
    stroke-miterlimit="10"
    stroke-width="0px"
  >
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_3" data-name="Layer 3" :fill="color">
      <path
        d="M6.15,23.14a.79.79,0,0,0,.53-.22.75.75,0,0,0,0-1.06L4.51,19.69l2.17-2.17a.75.75,0,0,0-1.06-1.06L3.45,18.63,1.28,16.46A.75.75,0,0,0,.22,17.52l2.17,2.17L.22,21.86a.75.75,0,0,0,0,1.06.79.79,0,0,0,.53.22.79.79,0,0,0,.53-.22l2.17-2.17,2.17,2.17A.77.77,0,0,0,6.15,23.14Z"
      />
      <path
        d="M45,6.68a.75.75,0,0,0,.53.22A.79.79,0,0,0,46,6.68l2.16-2.17,2.17,2.17a.79.79,0,0,0,.53.22.75.75,0,0,0,.53-.22.74.74,0,0,0,0-1.06L49.23,3.45,51.4,1.28a.74.74,0,0,0,0-1.06.75.75,0,0,0-1.06,0L48.17,2.39,46,.22A.75.75,0,0,0,45,.22a.74.74,0,0,0,0,1.06l2.16,2.17L45,5.62A.74.74,0,0,0,45,6.68Z"
      />
      <path
        d="M54.12,35.61a.74.74,0,0,0-1.06,0l-1.61,1.61-1.6-1.61a.75.75,0,0,0-1.06,1.06l1.6,1.61-1.6,1.61a.74.74,0,0,0,0,1.06.75.75,0,0,0,.53.22.79.79,0,0,0,.53-.22l1.6-1.61L53.06,41a.79.79,0,0,0,.53.22.75.75,0,0,0,.53-.22.74.74,0,0,0,0-1.06l-1.6-1.61,1.6-1.61A.74.74,0,0,0,54.12,35.61Z"
      />
      <path
        d="M20.53,5.56a.73.73,0,0,0,.53.22.74.74,0,0,0,.53-.22L23.2,4l1.61,1.61a.71.71,0,0,0,.53.22.74.74,0,0,0,.53-.22.75.75,0,0,0,0-1.06L24.26,2.89l1.61-1.61A.75.75,0,0,0,24.81.22L23.2,1.83,21.59.22a.75.75,0,0,0-1.06,0,.74.74,0,0,0,0,1.06l1.61,1.61L20.53,4.5A.74.74,0,0,0,20.53,5.56Z"
      />
      <path
        d="M52.35,15.74A7.35,7.35,0,0,0,42,11.43,8.25,8.25,0,0,0,27,14.7a9.72,9.72,0,0,0-15,11,7.91,7.91,0,0,0,2.21,15.51H35.2a9.74,9.74,0,0,0,9.72-9.73c0-.35,0-.69-.05-1h6.69a7.36,7.36,0,0,0,.79-14.67ZM35.2,39.67H14.15a6.41,6.41,0,0,1-1.06-12.73l1-.16-.41-.9a8.08,8.08,0,0,1-.75-3.42,8.23,8.23,0,0,1,16.45,0A7.77,7.77,0,0,1,29.19,24l-.36,1.87,1.54-1.12A8.22,8.22,0,1,1,35.2,39.67ZM51.56,28.91h-7a9.7,9.7,0,0,0-13.74-6.16,2.81,2.81,0,0,0,0-.29A9.65,9.65,0,0,0,28.35,16,6.76,6.76,0,0,1,41.06,12.8l.39.73.68-.45A5.86,5.86,0,0,1,51,16.62l.13.55.57,0a5.86,5.86,0,0,1-.18,11.72Z"
      />
    </g>
  </g>
</svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1d1d1b',
    },
  },
}
</script>

<style scoped>
</style>
