<template>
  <button
    class="hover:bg-gray-500 hover:bg-opacity-10 disabled:opacity-60 disabled:cursor-not-allowed btn"
    :class="{
      'bg-gray-300 hover:bg-gray-300 hover:bg-opacity-100': toggle && active,
      'border-2 border-black': outline,
      'text-black': !primary && !darkBg,
      'text-white': !primary && darkBg,
      'text-white bg-black hover:bg-opacity-80': primary && !darkBg && !color,
      'text-black bg-white focus:bg-opacity-100': primary && darkBg,
      [`bg-${color} text-white`]: color && primary,
      'leading-4 text-sm px-1 py-1': size === 'sm' && layout === 'icon-only',
      'leading-4 text-sm px-2 py-1': size === 'sm' && layout !== 'icon-only',
      'leading-6 text-base px-3 py-2': size === 'md',
      '': size === 'lg',
      '': size === 'xl',
      '': layout === 'normal',
      '': layout === 'icon-only',
      'inline-flex justify-center space-x-2': layout === 'icon-and-text',
    }"
    :title="description || label"
  >
    <div
      v-if="icon"
      :class="{
      'h-4': size === 'sm',
      'h-6': size === 'md',
      '': size === 'lg',
      '': size === 'xl',
    }"
    >
      <BaseIcon
        :icon="icon"
        :color="iconColor"
        :size="size"
        :active="active"
        :key="icon + size + active + label"
      />
    </div>
    <div v-if="layout !== 'icon-only'" class="label">
      {{ label }}
      <slot />
    </div>
  </button>
</template>

<script>
// button layouts:
// normal - rounded, border, text
// with icon - circle around icon text to the right of icon
// icon only - circle around icon

// button types
// normal
// primary
// toggle - class toggles on off on change of prop active

import BaseIcon from '@/components/shared/BaseIcon'
export default {
  name: 'BaseButton',
  components: {
    BaseIcon,
  },
  props: {
    layout: {
      type: String,
      default: 'normal', // 'icon-only' || 'icon-and-text'
    },
    darkBg: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconColor () {
      if (this.color) return this.color
      else if (this.darkBg || this.primary) return 'white'
      else return 'gray-800'
    },
    dynClass () {
      return {
        'bg-gray-300 hover:bg-gray-300': this.toggle && this.active,
        'border-2 border-black rounded-none': this.outline,
        'text-black': !this.primary && !this.darkBg,
        'text-white': !this.primary && this.darkBg,
        'text-white bg-black hover:bg-opacity-80': this.primary && !this.darkBg && !this.active,
        'text-black bg-white focus:bg-opacity-100': this.primary && this.darkBg,
        [`bg-${this.color}-500 text-white`]: this.color && this.primary,
        'leading-4 text-sm px-1 py-1': this.size === 'sm' && this.layout === 'icon-only',
        'leading-4 text-sm px-2 py-1': this.size === 'sm' && this.layout !== 'icon-only',
        'leading-6 text-base px-3 py-2': this.size === 'md',
        'inline-flex justify-center space-x-2': this.layout === 'icon-and-text',
      }
    },
  },
}
</script>

<style scoped>
  button.bright {
    color: var(--white);
  }
  button.normal.bright {
    border: 1px solid var(--white);
    color: var(--white);
  }
  button.icon-only, button.icon-and-text {
    border: none;
    background: none;
  }
  button.icon-only .icon, button.icon-and-text .icon {
    border: 1px solid var(--action2);
    border-radius: 1.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  button.icon-only .icon:not(:last-child), button.icon-and-text .icon:not(:last-child) {
    margin-right: 1rem;
  }

  button.icon-only.bright .icon, button.icon-and-text.bright .icon {
    border: 1px solid var(--white);
  }
  button.sm {
    font-size: .6rem;
  }
  button.sm .icon {
    height: 1rem;
    width: 1rem;
  }

  button.md {
    font-size: 0.75rem
  }
  button.md .icon {
    height: 1.5rem;
    width: 1.5rem;
  }
  button.lg {
    font-size: 0.9rem
  }
  button.lg .icon {
    height: 2rem;
    width: 2rem;
  }
  button.toggle.active {
    background-color: red
  }
</style>
