<template>
  <div class="flex items-center px-2 py-1 hover:bg-gray-500 hover:bg-opacity-10 rounded-md">
    <BaseIcon icon="BaseGlobe" class="mr-2" :color="darkBg ? 'white' : 'gray-800'"/>
    <BaseSelect
      v-model="language"
      :options="languages"
      :canDeselect="false"
      :canClear="false"
      :borderBottom="false"
      :darkBg="darkBg"
      class="w-12"
      data-cy="headerLanguageSelect"
      :custom-arrow="true"
    />
  </div>
</template>

<script>
import BaseSelect from '@/components/shared/BaseSelect.vue'
import languages from '@/i18n/languages'
import BaseIcon from '../BaseIcon.vue'

export default {
  data () {
    return {
      language: this.$store.state.cmsLocale,
      languages: languages,
    }
  },
  components: {
    BaseSelect,
    BaseIcon,
  },
  props: {
    darkBg: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    language: {
      immediate: false,
      deep: true,
      async handler (newValue, oldValue) {
        this.$i18n.locale = newValue
        this.$store.dispatch('setCmsLocale', newValue)
      },
    },
  },
}
</script>

<style scoped>

</style>
