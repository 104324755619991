import { store } from '@/store'
import { getCategoryId } from '@/composables/utils'

export default async (collectionId, compassId) => {
  try {
    store.commit('compass-sub-categories/clearAll')
    await store.dispatch('compass-sub-categories/find', {
      query: {
        compassId,
        locale: store.state.cmsLocale,
      },
    })
    if (!store.state['compass-sub-categories'].ids.length) {
      console.error('No compass sub-categories found')
      return
    }
    const collectionActions = await store.dispatch('standard-actions-collections/find', {
      query: {
        actionCollectionId: collectionId,
      },
    })
    const standardActionIds = collectionActions.data.map(action => action.standardActionId)
    const compassActions = store.getters['compass-actions/list']
      .filter(action => action.compassId === Number(compassId))
      .map(action => action.standardActionId)

    console.log(store.getters['compass-actions/list'])
    console.log(store.getters['standard-actions/list'])
    const standardActions = store.getters['standard-actions/list']
      .filter(action => standardActionIds.includes(action.id))
      .filter(action => !compassActions.some(item => item === action.id))

    if (!standardActions.length) {
      console.error('No standard actions found')
      return
    }
    const calls = []

    for (const action of standardActions) {
      if (!action.defaultSubCategoryId) {
        console.warn('No default sub-category ID found for action', action.title)
        continue
      }
      const actionDefaultCategoryId = getCategoryId(action.defaultSubCategoryId)
      if (!actionDefaultCategoryId) {
        console.error('No category ID found for action', action.title)
        continue
      }
      let compassSubCategory = store.getters['compass-sub-categories/list']
        .find(compassSubCat => compassSubCat.subCategoryId === action.defaultSubCategoryId && compassSubCat.compassId === Number(compassId))

      if (!compassSubCategory) {
        compassSubCategory = await store.dispatch(
          'compass-sub-categories/create', {
            compassId: Number(compassId),
            subCategoryId: action.defaultSubCategoryId,
          },
        )
        /* console.log('Action does not fit into any active subcategories, assigning to "other" subcategory')
        compassSubCategory = getOtherCompassSubcategory(actionDefaultCategoryId)
        if (!compassSubCategory) {
          console.error('No "other" subcategory found for category ID', actionDefaultCategoryId)
          continue
        } */
      }

      const newActionData = {
        title: action.title,
        description: null,
        categoryId: actionDefaultCategoryId,
        subCategoryId: action.defaultSubCategoryId,
        compassSubCategoryId: compassSubCategory.id,
        standardActionId: action.id,
        compassId: compassId ? Number(compassId) : null,
        isAccepted: true,
        isParked: false,
      }
      calls.push(store.dispatch('compass-actions/create', newActionData))
    }
    console.log('Creating ' + calls.length + '/' + standardActionIds.length + ' actions...')
    await Promise.all(calls)
    console.log('All actions created successfully!')
  } catch (error) {
    console.log('Error occurred:', error)
  }
}
