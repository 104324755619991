<template>
  <ul class="grid gap-12 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4" data-cy="projectList">
    <ProjectItem v-for="project in projects" :key="project.id" :projectId="project.id" :projectTitle="project.title" :project="project"/>
  </ul>
</template>

<script>
import ProjectItem from '@/components/projects/ProjectItem.vue'

export default {
  components: {
    ProjectItem,
  },
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped>

</style>
