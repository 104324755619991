import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class BoardNoteContent extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.

  static modelName = 'BoardNoteContent'
  // Define default properties here
  static instanceDefaults () {
    return {
      text: null,
      contentSize: null,
      mediaId: null,
      boardNoteId: null,
    }
  }

  static setupInstance (data, { models }) {
    if (data.media) {
      data.media = new models.api.Media(data.media)
    }
    return data
  }
}
const servicePath = 'board-notes-content'
const servicePlugin = makeServicePlugin({
  Model: BoardNoteContent,
  service: feathersClient.service(servicePath),
  servicePath,
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default servicePlugin
