<template>
  <BaseButton
    v-if="!user"
    :layout="'icon-and-text'"
    :icon="'BaseUser'"
    :darkBg="darkBg"
    @click="authAction"
    data-cy="headerLoginButton"
  >
    {{ $t('Header.Button04') }}
  </BaseButton>
  <div v-else class="flex items-center hover:bg-gray-500 hover:bg-opacity-10 rounded-md hover:cursor-pointer relative px-3 py-2" @click="showMenu = !showMenu">
    <BaseIcon icon="BaseUser" class="mr-2"/> {{ user.name }}
    <ul v-if="showMenu" class="absolute w-full top-10" style="background-color: #999">
      <li v-for="option in options" :key="option.value" class="px-2 py-2" @click="handleAction(option)">
        {{ option.label }}
      </li>
    </ul>
  </div>
</template>

<script>
import BaseButton from '@/components/shared/BaseButton'
import BaseIcon from '@/components/shared/BaseIcon.vue'

export default {
  data () {
    return {
      showMenu: false,
      options: [
        { value: 'log out', label: this.$t('Header.Button04_alt') },
        { value: 'change password', label: this.$t('Header.Button05') },
      ],
    }
  },
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    darkBg: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async authAction () {
      if (this.user === null) {
        this.$router.push('/login')
      } else {
        await this.$store.dispatch('auth/logout')
        location.href = '/'
      }
    },
    handleAction (option) {
      if (option.value === 'log out') {
        this.authAction()
      } else if (option.value === 'change password') {
        this.$router.push('/change-password')
      }
    },
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
  },
}
</script>

<style scoped>

</style>
