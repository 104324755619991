<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52.58 59.91"
    fill="none" :stroke="color" stroke-miterlimit="10" stroke-width="1.5px"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_3" data-name="Layer 3">
        <path d="M38.11,52.06l5.41-4-.34-2.54-2.24-.93L28.53,53.13a20.49,20.49,0,0,0,3.2,4.53C37,62.17,45.94,55,45.94,55l5.89-4.33V36.39l-2.24-1.23-2.08,1.23V48.2l-6.07,4.44a2.34,2.34,0,0,1-3.33-.58Z"/>
        <path d="M14.46,52.06l-5.4-4L9.4,45.5l2.23-.93L24,53.13a20.33,20.33,0,0,1-3.19,4.53C15.55,62.17,6.63,55,6.63,55L.75,50.7V36.39L3,35.16l2.08,1.23V48.2l6.07,4.44a2.32,2.32,0,0,0,3.32-.58Z"/>
        <circle cx="26.29" cy="6.28" r="5.53"/>
        <polyline points="36.32 29.48 32.1 15.88 20.48 15.88 16.26 29.48"/>
        <polyline points="23.86 44.67 23.86 22.29 28.71 22.29 28.71 44.67"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1d1d1b',
    },
  },
}
</script>

<style scoped>

</style>
