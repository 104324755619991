<template>
  <div class="fixed top-0 left-0 right-0 pt-5 pb-6 z-50">
    <div class="content-wrapper flex justify-between items-center">
      <router-link :to="'/'" class="self-stretch"><Brand :color="darkBg ? 'white' : 'black'"/></router-link>
      <Nav class="hidden sm:flex" />
      <div class="hidden sm:flex flex items-center">
        <User :darkBg="darkBg"/>
        <LanguageSelect :darkBg="darkBg" />
        <!-- <AdminSelect v-if="isAdmin" :darkBg="darkBg" /> -->
      </div>
      <HeaderMobile class="sm:hidden"/>
    </div>
  </div>
</template>

<script>
import HeaderMobile from '@/components/shared/header/HeaderMobile.vue'
import Brand from './Brand'
import Nav from './Nav'
import User from './User'
import LanguageSelect from './LanguageSelect.vue'
// import AdminSelect from './AdminSelect.vue'
// import Cms from './Cms.vue'

export default {
  components: {
    Brand,
    Nav,
    User,
    LanguageSelect,
    // AdminSelect,
    HeaderMobile,
  },
  props: {
    darkBg: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isAdmin () {
      return this.$store.getters['auth/isAuthenticated'] && this.$store.getters['auth/user'].roleId === 3
    },
  },
}
</script>

<style scoped>
</style>
